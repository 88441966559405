.homeMain{
    width: 100%;
    min-height: 98vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}





.homeMain .buttonListWrapper{

    width: fit-content;
    max-width: 100%;
     display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
 
    overflow: hidden;
  
    border-radius: 0.5rem;
     box-shadow: 0 0 0.5rem 0 white;
     padding:0.2rem;
    

}
.homeMain .buttonListWrapper .bW{
    width: 100%;
    padding:0.5rem;

}
.homeMain .buttonListWrapper button{
    width: 100%;
    border:none;
 
    padding: 0.5rem 1.2rem;

    font-family: "Chicle", serif;
     font-style: normal;
     color: white;
     font-size: 1.3rem;
     background: linear-gradient(175deg, rgba(255,200,200, 0.2 )5%,rgba(200,150,150,0.2), rgba(10,0,0,0.2));
     backdrop-filter: blur(0.8rem);

 
      text-shadow: 0.2rem 0.2rem 0.5rem black;;
      border-radius: 0.5rem;

      box-shadow: 0.05rem 0.08rem  0.1rem 0.05rem  rgba(255,255,255,0.5) inset,  0.08rem 0.1rem 0.15rem 0 black;

      white-space: pre-line;
      word-wrap: break-word;
}
 

.homeMain .buttonListWrapper button:hover{
    width: 100%;
    border:none;
 
    padding: 0.5rem 1.2rem;

    font-family: "Chicle", serif;
     font-style: normal;
     color: white;
     font-size: 1.3rem;
     background: linear-gradient(175deg, rgba(255,255,255, 0.2 )5%,rgba(200,200,200,0.2), rgba(10,0,0,0.2));
     backdrop-filter: blur(0.8rem);

 
      text-shadow: 0.2rem 0.2rem 0.5rem black;;
      border-radius: 0.5rem;

      box-shadow: 0.05rem 0.08rem  0.1rem 0.05rem  rgba(255,255,255,0.5) inset,  0.08rem 0.1rem 0.15rem 0 black;

      white-space: pre-line;
      word-wrap: break-word;
}
.homeMain .buttonListWrapper .bW:first-child button
{
    
}
.homeMain .buttonListWrapper .bW:last-child button
{
   /* border-radius: 0 0 0.3rem 0.3rem;
   border-top: solid white 2px;*/
}
/*
.homeMain .buttonListWrapper:last-child button{
    border-radius: 0 0 0.5rem 0.5rem ;
}
*/

.homeMain .whiteLine{
    width: 25%;
    height:0.1rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.2rem 0 white;
    margin: 0.2rem 0 0.2rem 0;


    margin: 1rem 0;
}