.wheel-main{
  position: relative;
  width: 100%;
  min-height: 98vh;
  padding: calc(min(calc(100vh/75), calc(100vw/35)));
}
.lucky-wheel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    
    position: relative;
    overflow: hidden;
    padding: 0.5rem;


  }
  .wheel-main .background-container{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    -webkit-background-repeat: no-repeat;

    background-size: cover;
    -webkit-background-size: cover;

    background-position: center; /* Keeps the image centered in the viewport */
    background-attachment: fixed;
    -webkit-background-attachment: fixed;

    background-image: url("/public/snowflakes-stars-are-falling-down-red-background_960396-240513.avif");
    filter:blur(0.5rem);
  }
  .wheel-main .actions{
    width: 100%;
    position: relative;
    z-index: 3;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap:wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    min-height: 5rem;

   

  }

 .wonTextWrapper .wonText{
    font-family: "Chicle", serif;
    font-weight: 400;
    font-style: normal;
    font-size: min(1.8rem, calc(min(100vh,100vw)/20));

    color:white;
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5); /* Festive shadow */

    white-space:pre-wrap;
    overflow-wrap: break-word;
    text-align: center;
 
  }

  .wonTextWrapper .subWonText{
    font-family: "Chicle", serif;
    font-weight: lighter;
    font-style: normal;
    font-size: min(1rem, calc(min(100vh,100vw)/22));

    color:white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* Festive shadow */

    white-space:pre-line;
    overflow-wrap: break-word;

    text-align: center;
    font-size: 1.2rem;
 
  }
  .lucky-wheel-container *{
    will-change: transform;

  }
   .walletButtonContainer{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
     border-radius: 0.5rem;

    padding: 2rem 2rem 1rem 2rem;
     cursor: pointer;


    background-image: url("../../public/19667569.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    
  }
  .wheel-main .walletIcon{
    width: 2rem;
    height: 2rem;
    background-image: url("../../public/icons8-ewallet-100.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 1rem 0 0 ;
 

  }
 .prizesButton{
    border: none;


    font-family: "Chicle", serif;
    font-weight: bold;
    font-size: 1.3rem;
    color: white;
    font-style: normal;
    background: none;
    white-space: nowrap; /* Prevent wrapping, including white spaces */
    overflow: hidden;    /* Hide overflowing text */
    text-overflow: ellipsis;
    text-shadow: 1px 1px 0.1rem black; /* Festive shadow */

    
   }
  .lucky-wheel-container .indicator{
    position: absolute;
    top:0;
    left: 50%;
    transform: translateX(-50%) translateY(-10%);
    z-index: 2;
    background-color: #FBB13C;;
    height: 3rem;
    width: 3rem;


    
    overflow: visible;

    clip-path:polygon(0% 0%, 50% 100%, 100% 0%, 0% 0%);

    filter: drop-shadow(2rem 2rem 3rem rgba(0, 0, 0, 0.5));
  }

.wheelPrizeModal{
  background:none;
}
.wheelPrizeModal .modal-content{
  background-color: rgba(20, 0, 0, 0.5);
  color: white;

  backdrop-filter: blur(0.5rem);

  box-shadow: 0 0 1rem 0.002rem #FBB13C;
   
}
.wheelPrizeModal .modal-body{
  padding-top: 0;
}
.wheelPrizeModal .modal-content .btn-close{
  filter: invert();
}

.wheelPrizeModal .modal-header {
  border-bottom: none; /* Removes the bottom border */
  font-family: "Chicle", serif;
  font-weight: 400;
  font-style: normal;
  font-size: min(1.8rem, calc(min(100vh,100vw)/30));

}
.wonTextWrapper{
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items:center;
  align-content: center;
  width: 100%;
  max-width: 100%;
}
.wonTextContainer{

    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items:center;
    align-content: center;

    background-image: url("./../../public/19564666.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    max-width: 100%;
    width: 100%;
    padding: 18% 9% 9% 9%;
    aspect-ratio: 2;
  }
  .wheel {
    width:   min(70vh,85vw);
    /*min(100%,70vh, 95vw);*/
    height:  min(70vh,85vw);
    max-width:90vw;
  
    /*min(100% , min(70vh, 95vw));*/
    border-radius: 200vw;
    position: relative;
    transform-origin: center;
   /* transition: transform 3s cubic-bezier(0.33, 1, 0.68, 1);*/
 
    overflow: hidden;
    pointer-events: none;

 
    box-shadow: 0 0 0.5rem 0.05rem #FBB13C;
  }

.wheel{
  background:#FBB13C;
  /* Inner shadow */
  border: 0.7rem solid #FBB13C; /* Edge for 3D feel */
  overflow: hidden; /* Contain internal elements */
}

 

  
  .wheel *{
    pointer-events: none;
  }
  
  .wheel .spin{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    
    width: 4rem;
    height: 4rem;
    top:50%;
    left:50%;
    pointer-events: all;
    transform: translate(-50%, -50%);
    padding: 1rem;
    border-radius:50%;
    border:none;
    font-size: 1.3rem;
    font-weight: bold;
    border: 0.3rem solid #FBB13C; /* Edge for 3D feel */
    box-shadow: 0 0 0.3rem 0.1rem #FBB13C;

    background: radial-gradient(circle, #ff7b7b, #d72638, #340d09);

    cursor: pointer;
    
  }
  @import url('https://fonts.googleapis.com/css2?family=Bonbon&family=Chicle&family=Great+Vibes&family=Modak&family=Molle&family=Shantell+Sans:ital,wght@0,300..800;1,300..800&display=swap');
 
  .segment {
    position: absolute;
    width: 100%;
    height: 100%;
    top:-50%;
    left: -50%;
    background-color: lightblue;
    transform-origin: 100% 100%;
    overflow: hidden;



 
    
  }
  
  .segment .span {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    transform: rotate(45deg) translateX(73%) translateY(0.2rem);
    font-size: min(calc(100vh/20),calc(100vw/18));

    text-align: center;
    color: black;

    font-family: "Chicle", serif;
    font-weight: 400;
    font-style: normal;


    color: black; /* Christmas red */
    text-shadow: 0.05rem 0.05rem 0.09rem black; /* Festive shadow */
    
    
    
  
    
  }
  