.authenticateMain{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0.5rem 0;

    font-family: "Chicle", serif;
    font-style:normal;
   

  
}
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.authenticateMain *{
    font-family: "Ubuntu", sans-serif;
    font-weight: normal;
    text-align: center;
}
.authenticateMain .infoText{
    color:white;
    font-weight: bold;
    padding-bottom: 0.5rem;

}
.authenticateMain .contentContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

  
}
.authenticateMain .contentContainer{
 
 
    backdrop-filter: blur(0.2rem);
    background: rgba(255,255,255,0.1);
    overflow: auto;
    border-radius: 1rem;
}
.authenticateMain .contentContainer .inputContainer .actionContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.authenticateMain .contentContainer .inputContainer{
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

}
.authenticateMain .contentContainer .inputContainer > *{
    width: 100%;
  
}
.authenticateMain .contentContainer .errorContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #FFC0C0;
    border-radius: 0.2rem;
    padding: 0.2rem 0.5rem;
    width: 100%;
    flex-grow: 1;
    flex-basis: 0;

    font-weight: normal;

}
.authenticateMain button{
    border:none;
    border-radius: 1rem;
    background-color: lightblue;
    padding: 0.5rem;


}

.authenticateMain .spam{
    width: 100%;
    height: 1rem;
}


@media screen and (orientation: portrait) {
    .authenticateMain .contentContainer{
        width: 98%;
        padding: 1rem 0.1rem;
        
     
    }
}
@media screen and (orientation :landscape) {
    .authenticateMain .contentContainer{
        width: auto;
        max-width: 90%;
        padding: 1rem;
       
    
   
    }
}

.authenticateMain .contentContainer .inputContainer .button{

    padding:0.8rem 1rem;
    border:none;
    border-radius: 5rem;
    background: rgba(255,100,100, 0.2);
    box-shadow: 0.1rem 0.1rem 0.2rem 0 white inset , 0.05rem 0.05rem 0.2rem 0 black inset, 0.05rem 0.05rem 0.2rem 0 black; 
    
    width: fit-content;
    min-width: fit-content;

    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: 1s;
    max-width: 100%;
    white-space: pre-line;
    word-wrap: break-word;
    margin:0.5rem 0;

}
.authenticateMain .contentContainer .inputContainer .button.disabled{

 
 
 
    box-shadow:0 0  0.2rem 0 black inset;
    
    width: fit-content;
    min-width: fit-content;

    color: white;
    font-weight: bold;
    cursor: pointer;


}