@import url('https://fonts.googleapis.com/css2?family=Bonbon&family=Chicle&family=Great+Vibes&family=Modak&family=Molle&family=Shantell+Sans:ital,wght@0,300..800;1,300..800&display=swap');


.userPrizeMain{
    position: relative;
    width: 100%;
    max-width: 100%;

    min-height: 100vh;
    margin:0;
    padding: 0;

}

.userPrizeMain .backgroundContainer{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    

    background-image: url("../../public/snowflakes-stars-are-falling-down-red-background_960396-240513.avif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    background-attachment: fixed;

    filter: blur(0.5rem);
    z-index: 1;

}
.userPrizeMain .userPrizeQrModal{
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    z-index: 6;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 5vh 0 5vh 0;



}
.userPrizeMain .userPrizeQrModal .info{
    color:black;
    padding-bottom: 1rem;

    white-space: pre-line;
    overflow-wrap: break-word;
    text-align: center;
}
.userPrizeMain .contentContainer{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    max-width: 100%;
    
 
    z-index: 2;
    padding: calc(min(calc(100vh/25), calc(100vw/35)));
padding-top: 0;
    
}
.userPrizeMain .prizeListWrapper{
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.userPrizeMain .prizeList{
    width: fit-content;
    max-width: 100%;
    min-width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;


}
.userPrizeMain .prizeWrapper{
    max-width: 100%;
    min-width: 100%;
    margin:0;
    padding:0.5rem;
    display: flex;
    justify-content: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    
}
.userPrizeMain .prizeBorderWrapper{
  
    max-width: 100%;
    min-width: 100%;
    margin:0;
    padding:0.5rem;
    background:  repeating-linear-gradient(
        45deg,
        red 0rem,
        red 0.6rem,
        white 0.7rem,
        white 1.4rem
    );
    border-radius: 0.8rem;
    box-shadow: 2px 2px 0.5rem 0.02rem black;
 
 
    
}
.userPrizeMain .prizeBorderWrapper.profile{
  
    max-width: 100%;
    box-shadow: none;
    min-width: fit-content;
    width: fit-content;
    margin:0;
    padding:1rem;
   
    background: none;
    border-radius: 0.8rem;
   
  
    
 
    
}
.userPrizeMain .prize{
    opacity: 100;
    max-width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    
    background-color: rgba(10,0,0,0,1);
   background-image: url("../../public/360_F_753421308_VMalSaeXrzPoi227g6xjsh5oSh1WtkAb.jpg");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;

   box-shadow: 0 00 0.9rem 0.01rem rgb(189, 0, 0);

   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: center;
   align-content: center;

  
 }
 .userPrizeMain .prize .qrWrapper{
    flex-grow: 1;
    display: flex;
    justify-content:flex-end;
    padding-left: 0.5rem;
 }
 .userPrizeMain .prize.profile{
    
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   justify-content: space-evenly;
   align-items: center;
   align-content: center;
   background: none;
   box-shadow: none;
   box-shadow: 2px 2px 0.5rem 0.02rem black, 0.1rem 0.1rem 0.3rem  0 white inset, -0.05rem -0.05rem 0.1rem  0 black inset;
   backdrop-filter: blur(0.5rem);


   padding: 1rem;
  
 }
 .userPrizeMain .prizeText{
  
 
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: flex-start;
   align-content: flex-start;

  
 }
 .userPrizeMain .profile .prizeText{
  
 
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    padding-top: 1rem;
    
 
   
  }
  .userPrizeMain .profile.whl .prizeText{
  
 
    cursor: pointer;
    padding: 0;
 
   
  }
.userPrizeMain .prize .title , .userPrizeMain .prize .subtitle{
    white-space: pre-line;
    overflow-wrap: break-word;

    font-family: "Chicle", serif;
     font-style: normal;
     color: white;

}
.userPrizeMain .prize .title{
    font-size: 1.2rem;
    font-weight: bold;
    text-shadow: 1px 1px 0.3rem black; /* Festive shadow */

}
.userPrizeMain .prize .subtitle{
    font-size: 1rem;
    font-weight: lighter;
    text-shadow: 1px 1px 0.1rem black; /* Festive shadow */

}
.userPrizeMain .prize.profile .subtitle{
    font-size: 1.2rem;

}

@media  screen and ( orientation:portrait) {
    .userPrizeMain .prizeList{
       
        min-width: 89vw;
    
    
    }

    
}
@media  screen and ( orientation:landscape) {
    .userPrizeMain .prizeList{
       
        min-width: 50vw;
    
    
    }

    
}

.userPrizeMain .savedPrizesTextWrapper
{
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    padding-left: 1rem;
}
.userPrizeMain .savedPrizesText{

    font-family: "Chicle", serif;
     font-style: normal;
     color: white;
     font-size: 1.3rem;
    text-shadow: 2px 2px 5px black;

}
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.prizeListWrapper .prizesInfo{

    font-family: "Ubuntu", sans-serif;
    font-weight: bold;
    text-align: center;

    color:white;

    white-space: pre-wrap;
    word-wrap: break-word;
    padding:1rem;
}