.genericTableMain {
    width: 100%;
    max-width: 100%;
    overflow: auto;
   }
  
  .genericTable {
    width: 100%;
    max-width: 100%;
    background-color: white ;
     border:none;

       table-layout: auto; 
       border-collapse: collapse;
      
   }
 
  .genericTable th, .genericTable td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
    max-width: 100%;
    white-space: pre-line;
    word-wrap: break-word;
  }
  .genericTable thead, .genericTable tbody, .genericTable tr{
      max-width: 100%;

  }
  
  .genericTable th {
    background-color: #f4f4f4;
  }

   
  
   