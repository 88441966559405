.itemCreateMain{
    width:100%;
}
.itemCreateModal{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
   
    align-items: flex-start;
    align-content: center;
}
.itemCreateModal > *
{
    margin: 0.2rem 0 0.2rem 0;
    width: 98%;
}
.itemCreateModal input[type="checkbox"] {
    /* Remove default styling */
    width: 20px;
    height: 20px;
    border: 2px solid #007BFF;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    position: relative;
    outline: none;
  }
.createItemSubmitButtonContainer{
    width:100%;
    display:flex;
    justify-content: flex-end;
}

.updateItemSubmitButtonContainer{
    width:100%;
    display:flex;
    justify-content: space-around;
    padding: 1rem;
}
.createItemSubmitButton{
    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;
    background-color: blueviolet;
    color: white;
    border: none;
    
}
.deleteItemSubmitButton{
    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;
    background-color: red;
    color: white;
    border: none;
    
}

.createItemSubmitButton:hover , .deleteItemSubmitButton:hover{
    box-shadow: 0 0 0.2rem 0.01rem black;

}
.createItemSubmitButton:active, .deleteItemSubmitButton:active{
    border-radius: 1.5rem;

   

}

.itemCreateModal  input{
    border-radius: 0.5rem;
    border: none;
    box-shadow: 0 0 0.2rem 0.01rem black;
    padding: 0.3rem;
}


.itemCreateActivationContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem;
}