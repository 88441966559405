@import url('https://fonts.googleapis.com/css2?family=Bonbon&family=Chicle&family=Great+Vibes&family=Modak&family=Molle&family=Shantell+Sans:ital,wght@0,300..800;1,300..800&display=swap');


.topbarMain{
    position: relative;
    z-index: 5;
    width: 100%;
    height: 4.5rem ;
    max-width: 100%;
  
    padding: 1rem;
    
   
     overflow: visible;

     background: linear-gradient(rgba(255,255,255,0.5) 70%, rgba(255,255,255,0));

   
     
}
.topbarMain.b1{
    backdrop-filter:none;
}
.topbarMain.b2{
    backdrop-filter: none;
}
.topbarMain .topbarContent{
    width: 100%;
    max-width: 100%;
    white-space: pre-line;
    overflow-wrap: break-word;
    height: 100%;
    background-color: red;
    border-radius:0.5rem;
    padding: 0.2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    background-image: url("../public/360_F_753421308_VMalSaeXrzPoi227g6xjsh5oSh1WtkAb.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    box-shadow: 0.2rem 0.2rem 0.8rem 0 rgba(150,0,0,8), 0.1rem 0.1rem 0.3rem 0.05rem white inset, -0.1rem -0.1rem  0.3rem 0 black inset;
}
.topbarMain .topbarContent *{
    max-width: 50%;
    white-space: pre-line;
    overflow-wrap: break-word;
}
.topbarMain .logo{
    padding-left: 1rem;
    font-family: "Chicle", serif;
     font-style:normal;
     font-size: 1.3rem;
     color: white;
     cursor:pointer;


}
.topbarMain .profile{
    padding-right: 1rem;
    font-family: "Chicle", serif;
     font-style:normal;
     font-size: 1rem;
     color: white;
     


}
.topbarMain .decorationWrapper{
    position: absolute;
    overflow: visible;
    top:0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.topbarMain .decoration{


    width:  10rem;
    height: 6rem;

    
    background-image: url("../public/pngtree-creative-art-of-cute-christmas-bells-with-red-bow-and-berries-png-image_13885006.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.topbarMain .blurer{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    filter: blur(2rem);
}




.appMain{
    position: relative;
    width: 100%;
    min-height: 100vh;
}
.appBackground{
    position: fixed;
     
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    -webkit-background-repeat: no-repeat;

    background-size: cover;
    -webkit-background-size: cover;

    background-position: center; /* Keeps the image centered in the viewport */
    background-attachment: fixed;
    -webkit-background-attachment: fixed;

    background-image: url("/public/snowflakes-stars-are-falling-down-red-background_960396-240513.avif");
    filter:blur(0.5rem);
    z-index: 0;
}
.appContent{
    position: absolute;
     
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 1;
    
}
.whiteSpace{
    width:0.2rem;
    height: 2rem;
}

 