.itemCreateMain{
    width:100%;
}
.itemCreateModal{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
   
    align-items: flex-start;
    align-content: center;
}
.itemCreateModal > *
{
    margin: 0.2rem 0 0.2rem 0;
}
.createItemSubmitButtonContainer{
    width:100%;
    display:flex;
    justify-content: flex-end;
}
.createItemSubmitButton{
    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;
    background-color: blueviolet;
    color: white;
    border: none;
    
}
.createItemSubmitButton:hover{
    box-shadow: 0 0 0.2rem 0.01rem black;

}
.createItemSubmitButton:active{
    border-radius: 1.5rem;

   

}

.itemCreateModal input{
    border-radius: 0.5rem;
    border: none;
    box-shadow: 0 0 0.2rem 0.01rem black;
    padding: 0.3rem;
}


.itemCreateActivationContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem;

}
input.itemCreateActivation , .itemCreateActivation{
    width: 3rem;
    height: 3rem;
    border-radius: 5rem;
    box-shadow: 0 0 0.3rem 0.01rem white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    font-size: large;
    font-weight: 800;
    color:red;
    text-shadow: 0.05rem 0.05rem 0.1rem black;
    background-color: rgba(255,255,255,0.5);
    backdrop-filter: blur(0.2rem);
}