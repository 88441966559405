 
body{
  width:100vw;
  min-height: 100vh;
  margin: 0;
 
}
*{
  max-width: 100%;
  width: auto;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: static;
 
}
html{
  margin: 0;
  padding:0;
 
}