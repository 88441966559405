.qrPrizeMain{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    align-content: center;
}

.qrPrizeMain .qrScanner{
    width: min(89vw, 89vh);
    border-radius: 1rem;
}
 .qrPrizeMain .changeCamera{
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem 0.02rem white;
    margin-top: 1rem;
    color: white;
 }
 .inputDeviceVariant{
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow:  0  0 0.5rem 0.002rem black;
    margin-bottom: 0.8rem;
 }

  .prizeValidationModalBody{
    width: 100%;
    max-width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
 }
 .prizeValidationModalBody .prizeValidationButton{
    border:none;
    background-color: #4681f4;
    border-radius: 1.5rem;
    box-shadow: 0 0 0.5rem 0 black;
    padding:0.5rem;
    color: white;
    font-weight: bold;
 }