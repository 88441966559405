.box-input-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 0.2rem;
}

.box-input-container .digit {
    width: 3rem;
    height: auto;
    aspect-ratio: 1;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.2s;
}

.box-input-container .digit:focus {
    border-color: #007bff;
}

.box-input-container .digit::-webkit-inner-spin-button, 
.box-input-container .digit::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.box-input-container .digit[type="text"] {
    -moz-appearance: textfield;
}
